import React, { useEffect } from 'react';

import { CAMPAIGN_STATUS, Checkbox, LocationWidget, Radio, RadioBox, SelectInput, StatusTag, Widget } from '../atoms';
import { useIntl } from 'react-intl';
import { ICampaignBudgetData, ICampaignData } from 'interface';
import { format } from 'date-fns';
import { CAMPAIGNS } from 'api';
import download from 'downloadjs';
import { CampaignFormat, CampaignLocations, CampaignSpend } from '../molecules';
import HourPartyScheduler from '@tpsengage/hour-parting-scheduler';
import { IScheduler } from 'interface/IScheduler';
import { DateTime } from 'luxon';
import AmChart from '../molecules/AmCart';
import AmTrafficChart from '../molecules/AmTrafficChart';
import ClassicCard from '../atoms/ClassicCard';
import { oneLine } from 'common-tags';
import { ChevronDown, Download, Filter2, InfoCircle } from 'react-iconly';
import Slider from 'react-slick';
import Popover from '../atoms/popover/popover';
import dynamic from 'next/dynamic';
import MapSearch from '../molecules/MapSearch';
import { ISugestion } from 'interface/ISugestion';
import { IOptions } from 'interface/ISelect';
const CampaignLocationsMap = dynamic(() => import('../molecules/CampaignLocationsMap'), {
  ssr: false,
});

const columnsShareablePage = [
  {
    name: 'Slots',
    value: 'slots',
  },
  {
    name: 'Est. Plays',
    value: 'plays',
  },
  {
    name: 'Est. Impressions',
    value: 'reach',
  },
  {
    name: 'Cost',
    value: 'cost',
  },
];

const mainColumns = [
  {
    name: 'Thumbnails',
    value: 'no',
  },
  {
    name: 'Name',
    value: 'name',
  },
  {
    name: 'Network',
    value: 'network',
  },
  {
    name: 'Type',
    value: 'type',
  },
  {
    name: 'Venue Type',
    value: 'venue',
  },
  {
    name: 'Plays per hour',
    value: 'plays/hour',
  },
  {
    name: 'Resolution',
    value: 'resolution',
  },
  {
    name: 'Screens',
    value: 'screens',
  },
  {
    name: 'Country, City',
    value: 'country/city',
  },
];

type IPropsCategoryMenu = {
  shareableInfo: boolean;
  filters: any;
  setFilters: (filter: string[]) => void;
};
const CategoryMenu = ({ filters, setFilters, shareableInfo }: IPropsCategoryMenu) => {
  const isChecked = (value: string): boolean => {
    return filters.indexOf(value) > -1;
  };

  return (
    <ul className="flex flex-col gap-2">
      {(shareableInfo ? [...mainColumns, ...columnsShareablePage] : [...mainColumns]).map((el, index: number) => {
        return (
          <li key={index}>
            <Checkbox
              name="primary"
              defaultChecked={isChecked(el.value)}
              onChange={(event: any) => {
                const f = [...filters];
                if (event.target.checked) {
                  f.push(el.value);
                } else {
                  let i = f.indexOf(el.value);
                  if (i > -1) {
                    f.splice(i, 1);
                  }
                }
                setFilters(f);
              }}
              labelColor="dark"
              label={el.name}
            />
          </li>
        );
      })}
    </ul>
  );
};

type IProps = {
  campaign: ICampaignData;
  campaignBudget: ICampaignBudgetData;
  shareableInfo: boolean;
};

const CampaignInfo = ({ campaign, campaignBudget, shareableInfo }: IProps) => {
  const intl = useIntl();
  const [filterType, setFilterType] = React.useState('day');
  const [totalLocations, setTotalLocations] = React.useState<string>('0');
  const [schedules, setSchedules] = React.useState<IScheduler[]>([]);
  const [activeSchedule, setActiveSchedule] = React.useState<IScheduler>();
  const [totalCircuit, setTotalCircuit] = React.useState<string>('0');
  const [totalSlots, setTotalSlots] = React.useState<string>('0');
  const [totalReach, setTotalReach] = React.useState<string>('0');
  const [totalBudget, setTotalBudget] = React.useState<string>('0');
  const [status, setStatus] = React.useState<CAMPAIGN_STATUS>('PAUSED');
  const [selectedLocation, setSelectedLocation] = React.useState<any>(null);
  const [totalPerLocation, setTotalPerLocation] = React.useState<string>('0');
  const [searchMapLocation, setSearchMapLocation] = React.useState<any>(null);
  const [searchBillboard, setSearchBillboard] = React.useState<any>('');
  const [showSearch, setShowSearch] = React.useState<boolean>(false);
  const [visibleColumns, setVisibleColumns] = React.useState<string[]>(
    shareableInfo
      ? [
          'slots',
          'plays',
          'reach',
          'cost',
          'no',
          'name',
          'network',
          'type',
          'venue',
          'plays/hour',
          'resolution',
          'contentType',
          'screens',
          'country/city',
        ]
      : ['no', 'name', 'network', 'type', 'venue', 'plays/hour', 'resolution', 'screens', 'country/city']
  );

  React.useEffect(() => {
    //check if campaign has newStatus logic
    let oldStatus = campaign.campaignStatus ? campaign.campaignStatus : campaign.status;

    if (!campaign.campaignStatus) {
      if (oldStatus === 'running' && campaign.startDate > Date.now()) {
        oldStatus = 'ready';
      }
      if (status === 'running' && campaign.startDate < Date.now()) {
        oldStatus = 'completed';
      }
    }
    const totalMedia = campaign.formats.reduce((a, b) => a + b.media.length, 0);

    if (oldStatus === 'APPROVED_CONTENT' && totalMedia === 1) {
      oldStatus = 'ALL_APPROVED_CONTENT';
    }
    setStatus(oldStatus);
  }, []);

  const lineContainerClasses = oneLine`
  flex md:flex-col
  justify-between md:justify-start
  pb-3 md:pb-0

  `;

  const headerClasses = oneLine`
  text-base md:text-xs
  dark:text-dark-400
  font-medium
  tracking-tighter
  pb-1
  `;

  const valueClasses = oneLine`
  text-lg md:text-sm
  text-dark-default
  dark:text-white
  font-semibold md:font-bold
  text-right md:text-left
  `;

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const viewOptions = [
    { value: 'day', label: 'Day', checked: true },
    { value: 'hour', label: 'Hour', checked: false },
  ];

  useEffect(() => {
    let totalL = 0;
    let totalC = 0;
    let totalSl = 0;
    let totalRch = 0;

    const locations: string[] = [];
    for (const item of campaign.schedules) {
      totalSl += item.slots.length;
      if (item.screens)
        for (const screen of item.screens) {
          if (!locations.includes((screen as any).uid)) {
            locations.push((screen as any).uid);
            totalL += 1;
            totalC += screen.circuit;
          }
        }
    }

    for (const item of campaign.screens) {
      if (item && item.reach) totalRch += item.reach;
    }

    setTotalLocations(`${totalL}`);
    setTotalCircuit(`${totalC}`);
    setTotalSlots(`${totalSl}`);
    setTotalReach(`${totalRch}`);

    let sch: IScheduler[] = [];
    let newSchedules: IScheduler[] = JSON.parse(JSON.stringify(campaign.schedules));
    for (let schedule of newSchedules) {
      schedule.slots = schedule.slots.sort((a, b) => a - b);
    }
    newSchedules = newSchedules.sort((a, b) => a.slots[0] - b.slots[0]);
    for (let schedule of newSchedules) {
      if (schedule.screens) schedule.locations = schedule.screens;

      schedule.slots = [...schedule.slots].map((el: number) => {
        let dt = DateTime.local();
        // let date = DateTime.fromMillis(el as number);
        let date = DateTime.fromMillis(el as number, { zone: 'utc' });

        return date.valueOf() / 1000;
      });

      schedule.readOnly = true;
      schedule.notSelectedPartial = [];
      schedule.title = schedule.type === 'daily' ? 'Daily' : 'Hourly';
      schedule.locations = schedule.locations.map(el => {
        return {
          ...el,
          restriction: [],
        };
      });
      sch.push(schedule);
    }
    setSchedules(newSchedules);

    setActiveSchedule(sch[0]);
    getBudget();
  }, []);

  const getBudget = async () => {
    if (campaignBudget) {
      setTotalBudget(campaignBudget.displayPrice);
      setTotalPerLocation(campaignBudget.costPerScreenVal);
    }
  };
  const getMediaPlan = async (uuid: string) => {
    const result: any = await CAMPAIGNS.getMediaPlan(`${uuid}`);
    download(result, `${campaign.name} Campaign Media Plan by Blindspot.pdf`, 'application/pdf');
  };

  const getInvoice = async (uuid: string) => {
    const result: any = CAMPAIGNS.getInvoice(`${campaign?.invoice?.uuid}`);

    // download(
    //   result.data,
    //   `TPSENGAGE_${caminvoice.series}-${invoice.number}-${DateTime.fromMillis(
    //     invoice.createdAt
    //   ).toFormat('yyyy MM dd')}.pdf`,
    //   'application/pdf'
    // );
  };

  const schedulesLocation: IOptions[] = campaign.schedules.map((el, index: number) => {
    return {
      label: el.screens?.[0].name,
      value: el?.uid,
    } as IOptions;
  });
  const activeScheduleSelected: IOptions = schedulesLocation.find(el => el.value === activeSchedule?.uid) as IOptions;
  return (
    <div className="space-y-7 px-7 pb-7">
      <div className="flex flex-col md:flex-row md:space-x-7">
        <div className="flex flex-col md:w-8/12 space-y-7 pt-7 md:pt-0">
          <ClassicCard otherClasses="px-7  py-6 shadow-card">
            <div className="">
              <h3 className="pb-4 text-lg font-bold tracking-tighter dark:text-dark-400">Basic Info</h3>
            </div>
            <div className="">
              <div className="list-details md:grid md:grid-cols-3 gap-7">
                <div className={lineContainerClasses}>
                  <div className={headerClasses}>Campaign name</div>
                  <div className={valueClasses}>{campaign.name}</div>
                </div>
                {shareableInfo && (
                  <div className={lineContainerClasses}>
                    <div className={headerClasses}>Campaign PO #</div>
                    <div className={valueClasses}>{'BS-' + campaign.poNumber}</div>
                  </div>
                )}
                {!shareableInfo && (
                  <div className={lineContainerClasses}>
                    <div className={headerClasses}>Campaign status</div>
                    <div className={valueClasses}>
                      <StatusTag text={status} status={status}></StatusTag>
                    </div>
                  </div>
                )}
                {shareableInfo && (
                  <div className={lineContainerClasses}>
                    <div className={headerClasses}>Campaign status</div>
                    <div className={valueClasses}>
                      <StatusTag
                        text={campaign.hasCampaign ? 'Published' : 'Draft'}
                        status={campaign.hasCampaign ? 'Published' : 'Draft'}
                      ></StatusTag>
                    </div>
                  </div>
                )}
                <div className={lineContainerClasses}>
                  <div className={headerClasses}>Promoted industry</div>
                  <div className={valueClasses}>{campaign.industry.label}</div>
                </div>
                {!shareableInfo && campaign.campaignType !== 'group' && (
                  <div className={lineContainerClasses}>
                    <p className={headerClasses}>Media Plan</p>
                    <p className={`flex  items-center space-x-2 ${valueClasses}`}>
                      {campaign.hasMediaPlan && (
                        <>
                          <a
                            className="underline cursor-pointer text-primary-500"
                            onClick={() => getMediaPlan(campaign.uuid)}
                          >
                            Download Media Plan
                          </a>
                          <div>
                            <Download set="light" primaryColor="#F53D3F" size="small" />
                          </div>
                        </>
                      )}
                    </p>
                  </div>
                )}

                <div className={lineContainerClasses}>
                  <p className={headerClasses}>Invoice</p>
                  {!campaign.invoice && <p className={valueClasses}>Pending</p>}
                  {campaign.invoice && (
                    <p className={valueClasses}>
                      <a className="underline cursor-pointer" onClick={() => getInvoice(campaign.uuid)}>
                        {campaign.invoice.series}-{campaign.invoice.number} (
                        {format(campaign.invoice.createdAt, 'yyyy-MM-dd')})
                      </a>
                    </p>
                  )}
                </div>

                <div className={lineContainerClasses}>
                  <div className={headerClasses}>Start date</div>
                  <div className={valueClasses}>
                    {campaign.startDate
                      ? DateTime.fromMillis(campaign.startDate as number, { zone: 'utc', locale: 'utc' }).toFormat(
                          'dd-MM-yyyy HH:mm'
                        )
                      : 'N/A'}
                  </div>
                </div>
                <div className={lineContainerClasses}>
                  <div className={headerClasses}>End date</div>
                  <div className={valueClasses}>
                    {campaign.endDate
                      ? DateTime.fromMillis(campaign.endDate as number, { zone: 'utc', locale: 'utc' }).toFormat(
                          'dd-MM-yyyy HH:mm'
                        )
                      : 'N/A'}
                  </div>
                </div>
                <div className={lineContainerClasses}>
                  <div className={headerClasses}>Created on</div>
                  <div className={valueClasses}>
                    {DateTime.fromMillis(campaign.createdAt as number, { zone: 'utc', locale: 'utc' }).toFormat(
                      'dd-MM-yyyy HH:mm'
                    )}
                  </div>
                </div>
                <div className={lineContainerClasses}>
                  <div className={headerClasses}>Last modified on</div>
                  {campaign.modifiedAt && (
                    <div className={valueClasses}>
                      {DateTime.fromMillis(campaign.modifiedAt as number, { zone: 'utc', locale: 'utc' }).toFormat(
                        'dd-MM-yyyy HH:mm'
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ClassicCard>

          <ClassicCard otherClasses="px-7  py-6  shadow-card h-full">
            <Slider {...settings} className={`arrows narrow`}>
              {campaign.formats.map((el, index: number) => {
                return <CampaignFormat index={index} total={campaign.formats.length} format={el} key={index} />;
              })}
            </Slider>
          </ClassicCard>
        </div>
        {!shareableInfo && (
          <div className="md:w-4/12 ">
            <div className="flex flex-row flex-wrap h-full md:flex-col md:space-y-7 campaign-kpis">
              <LocationWidget title="Total Locations" value={totalLocations} icon="Location" />
              <LocationWidget title="Total Screens" value={totalCircuit} icon="Activity" />
              <LocationWidget title="Total Hourly Slots" value={totalSlots} icon="TimeCircle" />
              {campaign.campaignType !== 'group' ? (
                <>
                  <LocationWidget title="Total Budget" value={totalBudget} icon="Wallet" />
                  <LocationWidget title="Min. Cost per Location" value={totalPerLocation} icon="Chart" />
                </>
              ) : (
                <LocationWidget title="Campaign Spent" value={campaign.budgetData?.totalMargin || ''} icon="Chart" />
              )}
            </div>
          </div>
        )}
        {shareableInfo && (
          <div className="md:w-4/12 mt-7 md:mt-0">
            <div className="flex flex-col h-full space-y-7">
              <div className="flex flex-col flex-wrap h-full space-y-7">
                <div className="flex flex-row space-x-7">
                  <LocationWidget otherClass="w-6/12" title="Total Locations" value={totalLocations} />
                  <LocationWidget otherClass="w-6/12" title="Total Screens" value={totalCircuit} />
                </div>
                <div className="flex flex-row space-x-7">
                  <LocationWidget otherClass="w-6/12" title="Total Est. Plays" value={campaign.plays} />
                  <LocationWidget otherClass="w-6/12" title="Total Est. Impressions" value={totalReach} />
                </div>
                <div className="flex flex-row space-x-7">
                  <LocationWidget otherClass="w-6/12" title="Total Hourly Slots" value={totalSlots} />
                  <LocationWidget otherClass="w-6/12" title="Min. Cost per Location" value={totalPerLocation} />
                </div>
              </div>

              {campaign.campaignType !== 'group' ? (
                <ClassicCard otherClasses={'px-5 py-4 shadow-card bg-gradient-red dark:bg-gradient-red-dark h-full'}>
                  <div className="">
                    <h3 className="pb-4 text-lg font-bold tracking-tighter dark:text-dark-400">Bill summary</h3>
                  </div>

                  <ul className="pb-4 border-b review-steps border-bordercolor">
                    <li className="flex items-center justify-between">
                      <div className="font-bold text-dark-default dark:text-dark-400">Subtotal</div>
                      <div className="font-semibold dark:text-dark-400">
                        {campaignBudget?.subTotalRoundVal || campaign?.budget?.subTotal}
                      </div>
                    </li>
                    <li className="flex items-center justify-between">
                      <div className="font-bold text-dark-default dark:text-dark-400">Tax</div>
                      <div className="font-semibold dark:text-dark-400">
                        {campaignBudget?.vat || campaign?.budget?.tax}
                      </div>
                    </li>
                    {campaign.budget.tlpValue !== 0 && (
                      <li className="flex items-center justify-between">
                        <div className="font-bold text-dark-default dark:text-dark-400">Local Advertisement TAX</div>
                        <div className="font-semibold dark:text-dark-400">
                          {campaignBudget?.tlp || campaign?.budget?.tlp}
                        </div>
                      </li>
                    )}
                    {campaign.budget.handleFee !== 0 && (
                      <li className="flex items-center justify-between">
                        <div className="font-bold text-dark-default dark:text-dark-400">Handling fee</div>
                        <div className="font-semibold dark:text-dark-400">
                          {campaignBudget?.handleFeeVal || campaign?.budget?.handleFeeVal}
                        </div>
                      </li>
                    )}
                    {campaign.budget.usedCredits && campaign.budget.usedCredits !== 0 && (
                      <li className="flex items-center justify-between">
                        <div className="font-bold text-dark-default dark:text-dark-400">Used Credit</div>
                        <div className="font-semibold dark:text-dark-400">
                          {campaignBudget?.credits || campaign?.budget?.usedCredits}
                        </div>
                      </li>
                    )}
                    {campaign.budget.percentDiscount !== 0 && (
                      <li className="flex items-center justify-between">
                        <div className="font-bold text-dark-default dark:text-dark-400">Subtotal with used credit</div>
                        <div className="font-semibold dark:text-dark-400">
                          {campaignBudget?.credits || campaign?.budget?.subtotalWithUsedCredit}
                        </div>
                      </li>
                    )}
                    {campaign.budget.percentDiscount !== 0 && (
                      <li className="flex items-center justify-between">
                        <div className="font-bold text-dark-default dark:text-dark-400">
                          Discount voucher ({campaign.budget.percentDiscount}%){' '}
                        </div>
                        <div className="text-sm font-semibold dark:text-dark-400">
                          -{campaignBudget?.virtualcredits || campaign.budget?.valuePercentDiscount}
                        </div>
                      </li>
                    )}
                  </ul>

                  <div className="flex items-center justify-between mt-4">
                    <div className="font-bold text-dark-default dark:text-dark-400">Total</div>
                    <div className="font-semibold dark:text-dark-400">
                      {campaignBudget.totalRoundCurrency || campaign.budget?.totalWithCalcApplied}
                    </div>
                  </div>
                </ClassicCard>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {!shareableInfo && campaign.campaignType !== 'group' && !campaign.hideSpend && (
        <CampaignSpend campaignId={campaign.uuid} budget={totalBudget} campaignType={campaign.campaignType} />
      )}

      <ClassicCard otherClasses="px-7  py-6  shadow-card">
        <div className="flex flex-row flex-nowrap justify-between">
          <h3 className="pb-4 text-lg font-bold tracking-tighter dark:text-dark-400">Locations</h3>
          <Popover
            className="rightbottom"
            handler={
              <>
                <div
                  className={`input-simulate cursor-pointer flex items-center justify-between text-body dark:text-dark-300  flex-1 ${
                    showSearch ? 'active' : ''
                  }`}
                  onClick={() => {
                    setShowSearch(!showSearch);
                  }}
                >
                  <label className="cursor-pointer dark:text-dark-400">
                    Shown columns:
                    <span className="mr-2 text-primary-500 text-bold capitalize-fl">
                      &nbsp;
                      {visibleColumns
                        ? visibleColumns.toString().split(',').length <= 1
                          ? visibleColumns.toString().split(',')
                          : visibleColumns.toString().split(',').length + ' columns'
                        : 'All columns'}
                    </span>
                  </label>
                  <ChevronDown set="light" size="small" />
                </div>
                {/* <div className="box-content px-4 py-3 border dark:border-dark-400 md:hidden text-body dark:text-dark-400 rounded-3xl">
                  <Filter2 set="light" />
                </div> */}
              </>
            }
            content={
              <CategoryMenu
                shareableInfo={shareableInfo}
                filters={visibleColumns}
                setFilters={f => {
                  setVisibleColumns(f);
                }}
              />
            }
          />
        </div>
        <CampaignLocations screens={campaign.screens} shareableInfo={shareableInfo} visibleColumns={visibleColumns} />
      </ClassicCard>

      <ClassicCard otherClasses="px-7 py-6  shadow-card">
        <div className="flex flex-row flex-wrap justify-between items-center mb-4">
          <h3 className="text-lg font-bold tracking-tighter dark:text-dark-400 whitespace-nowrap">Locations Map</h3>
          <div className="flex flex-row search-map-inputs">
            <MapSearch
              fromCampaignPage={true}
              popoverMobile={false}
              defaultValueLocName={''}
              showList={true}
              onChangeLocName={(name: string) => {
                setSearchBillboard(name);
              }}
              onChange={(location: ISugestion) => {
                setSearchMapLocation(location);
              }}
              countryArea=""
            />
          </div>
        </div>
        <CampaignLocationsMap
          isBillboard={true}
          isShareMap
          searchLocation={searchMapLocation}
          searchBillboard={searchBillboard}
          screens={campaign.screens}
        />
      </ClassicCard>

      {shareableInfo && (
        <ClassicCard otherClasses="flex-1 flex-col items-stretch md:w-auto py-6 pr-6 flex mx-4 md:m-0 items-center border-light">
          <div className="flex flex-row pb-4">
            <div>
              <div className="cursor-pointer excl-mark mx-7" data-tip data-for="error">
                !
              </div>
            </div>
            <div className="md:text-sm md:font-semibold md:tracking-tighter md:text-dark-default dark:text-dark-400">
              Final number of ad plays and budget may vary slightly due to each location’s audience data
            </div>
          </div>
          <div className="flex flex-row">
            <div>
              <div className="cursor-pointer excl-mark mx-7" data-tip data-for="error">
                !
              </div>
            </div>
            <div className="md:text-sm md:font-semibold md:tracking-tighter md:text-dark-default dark:text-dark-400">
              The pricing and availability of the locations are subject to change. Blindspot and its partners do their
              best to provide your selection, however it cannot be guaranteed until the campaign is approved
            </div>
          </div>
        </ClassicCard>
      )}

      {!shareableInfo && (
        <ClassicCard otherClasses="px-7  py-6  shadow-card">
          <div className="flex flex-wrap items-center justify-between pb-4">
            <h3 className="flex flex-col text-lg font-bold tracking-tighter dark:text-dark-400 align-center">
              Performance
              <div className="flex flex-row items-center justify-center">
                <div className="flex items-center justify-center cursor-pointer" data-tip data-for="utc">
                  <InfoCircle set="bold" primaryColor="#FDA53F" size="small" />
                </div>
                <p className="text-sm font-semibold text-center text-dark-500 dark:text-dark-300 md:text-left">
                  {selectedLocation ? (
                    <>The data is displayed in the location's timezone (UTC {selectedLocation.tz})</>
                  ) : (
                    <>
                      The data is displayed in your browser timezone (UTC
                      {DateTime.local().offset / 60 > 0
                        ? ` + ${DateTime.local().offset / 60}`
                        : DateTime.local().offset / 60}
                      )
                    </>
                  )}
                </p>
              </div>
            </h3>
            <RadioBox
              boxSize="big"
              key="1"
              type="radio"
              name="primary"
              flexClass="flex-1 is-center-box"
              onChange={(e: any) => {
                setFilterType(e.target.value);
              }}
              options={viewOptions}
            />
            {(campaign.screens.length > 1 && (
              <div className="flex-1 mt-5 md:flex-none md:mt-0">
                <Popover
                  className={`bottom chevron-transform`}
                  handler={
                    <div className="flex items-center justify-center space-x-2 cursor-pointer">
                      <div className="text-lg font-semibold dark:text-dark-400 md:text-sm md:font-bold text-lightGrey">
                        {selectedLocation ? selectedLocation.label : 'Overall performance'}
                      </div>
                      <div className="chevron dark:text-dark-400 text-body">
                        <ChevronDown set="light" size="small" />
                      </div>
                    </div>
                  }
                  content={
                    <div className="flex flex-col p-2 space-y-2 w-72">
                      <Radio
                        key={`radio--1`}
                        onChange={(e: any) => {
                          setSelectedLocation(null);
                        }}
                        name="performanceDash"
                        label="Overall"
                        value="-1"
                        defaultChecked={selectedLocation === null}
                        labelColor={'dark'}
                      ></Radio>
                      {campaign.screens.map((el, index) => {
                        return (
                          <Radio
                            key={`radio-${index}`}
                            onChange={(e: any) => {
                              const found = campaign.screens[e.target.value];
                              setSelectedLocation({
                                label: found.name,
                                value: found.uuid,
                                tz: found.tz,
                                externalIds: found.externalIds ? `${found.externalIds.join(',')},${found.uuid}` : '',
                              });
                            }}
                            defaultChecked={selectedLocation?.label === campaign.screens[index].name}
                            name="performanceDash"
                            label={el.name}
                            value={index}
                            labelColor={'dark'}
                          ></Radio>
                        );
                      })}
                    </div>
                  }
                />
              </div>
            )) || <></>}
          </div>

          <div className="space-y-6">
            <AmChart
              chartTitle="Plays"
              screen={selectedLocation ? selectedLocation : ''}
              uuid={campaign.uuid}
              tz={selectedLocation ? selectedLocation.tz : DateTime.local().zoneName}
              filterType={filterType}
              type={campaign.campaignType}
            />

            <AmTrafficChart
              chartTitle="Optimized reach"
              screen={selectedLocation ? selectedLocation : ''}
              uuid={campaign.uuid}
              tz={selectedLocation ? selectedLocation.tz : DateTime.local().zoneName}
              filterType={filterType}
              type={campaign.campaignType}
            />
          </div>
        </ClassicCard>
      )}
      {activeSchedule && campaign.campaignType !== 'group' ? (
        <ClassicCard otherClasses="px-7  py-6 overflow-hidden  shadow-card">
          <div className="flex flex-wrap items-center justify-between md:pb-4">
            <h3 className="flex-1 text-lg font-bold tracking-tighter dark:text-dark-400 md:flex-none ">
              Schedule {activeSchedule.type === 'daily' ? 'Daily' : 'Hourly'}
            </h3>
            {/* {(campaign.schedules.length > 1 && (
              <div className="w-full mt-4 md:w-auto md:mt-0">
                <Popover
                  className={`bottom chevron-transform`}
                  handler={
                    <div className="flex items-center space-x-2 cursor-pointer">
                      <div className="text-sm font-bold text-primary-default">Schedule for</div>
                      <div className="chevron">
                        <ChevronDown set="light" primaryColor="#858D96" size="small" />
                      </div>
                    </div>
                  }
                  content={
                    <div className="flex flex-col p-2 space-y-2 w-72">
                      {schedules.map((el, index) => {
                        return (
                          <Radio
                            key={`radio-${index}`}
                            onChange={(e: any) => {
                              setActiveSchedule(el);
                            }}
                            defaultChecked={activeSchedule === el}
                            name="schedulersOptions"
                            label={el.title + '-' + el.locations.length + ' locations'}
                            value={index}
                            labelColor={'dark'}
                          ></Radio>
                        );
                      })}
                    </div>
                  }
                />
              </div>
            )) || <></>} */}
          </div>
          <div>
            {activeSchedule && campaign.campaignType !== 'group' && (
              <div>
                <ClassicCard>
                  <div className="py-4 text-base font-bold md:py-5 md:text-sm text-dark-default dark:text-dark-400">
                    Select the location for which you want to view the schedule
                  </div>
                  <div className="flex flex-wrap space-x-2 md:space-x-4">
                    <SelectInput
                      options={schedulesLocation}
                      defaultValue={activeScheduleSelected}
                      onChange={e => {
                        const selectedItem = schedules.find(el => el?.uid === e.value);
                        setActiveSchedule(selectedItem);
                      }}
                      label="Location"
                      data-cy="Location"
                      dataCy="Location"
                      isMulti={false}
                      fullWidth={true}
                      isClearable={false}
                      selName="Location"
                    />
                  </div>
                </ClassicCard>
                <HourPartyScheduler
                  key={activeSchedule.uid}
                  readOnly={true}
                  iconLeft="bi:arrow-left-circle-fill"
                  iconRight="bi:arrow-right-circle-fill"
                  scheduler={[activeSchedule]}
                />
              </div>
            )}
          </div>

          {/* <div>
    {schedules &&
      schedules.map((el, index: number) => {
        return (
          <div className="w-full px-4 py-2 mb-5 bg-gray-600" key={index}>
            <h3 className="text-xl font-bold text-white">Schedule {el.type === 'daily' ? 'Daily' : 'Hourly'} </h3>
            <HourPartyScheduler
              readonly={true}
              onChange={() => {}}
              iconLeft="bi:arrow-left-circle-fill"
              iconRight="bi:arrow-right-circle-fill"
              scheduler={[el]}
            />
          </div>
        )
      })}
  </div> */}
        </ClassicCard>
      ) : (
        ''
      )}
    </div>
  );
};
export default CampaignInfo;
