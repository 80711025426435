import React, { useCallback } from 'react';
import { Stack } from 'components/layout';

import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import { Button, Input, SelectInput, Header } from 'components/common/atoms';
import { AddressField } from 'components/common/molecules';
import { IOptions } from 'interface/ISelect';
const basePath = process.env.NEXT_PUBLIC_API_BASEPATH ?? '';
import { ISugestion } from 'interface/ISugestion';
import AuthContainer from './auth/AuthContainer';
import { TPSAPI } from '@api/users';
import { gtmRegisterUser } from 'api/gtm/gtm';
import { useRouter } from 'next/router';
import { useApp } from 'components/app';
import ReactTooltip from 'react-tooltip';

const optionsValues: IOptions[] = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

const optionsTooltip: IOptions[] = [
  {
    value: 'address',
    label: "You can select your or your company's address from the drop down menu, just start typing something in.",
  },
  {
    value: 'company',
    label:
      'This is required for our internal records. You can put in your EIN/TIN or any other way we can help identify your company for invoicing.',
  },
  { value: 'vat', label: "If you're a European company and do not pay VAT, select no. Otherwise, select yes." },
];

const RegisterFormStep2 = () => {
  const intl = useIntl();
  const [api] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(
        2,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
    companyId: Yup.string()
      .min(
        4,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '4' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
    address: Yup.object()
      .test(
        'test-name',
        intl.formatMessage({
          defaultMessage: 'The address you have entered is incorect',
          id: 'Error.addressincorect',
        }),
        (value: any) => {
          if (value) {
            if (!value.label) return false;
            if (!value.locationId) return false;
            if (!value.county) return false;
            if (!value.latitude) return false;
            if (!value.longitude) return false;
            return true;
          } else {
            return false;
          }
        }
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
  });

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );

  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit, formState, setValue, control } = useForm({
    resolver,
    defaultValues: {
      company: '',
      address: {},
      vat: '',
      companyId: '',
      additionalId: '',
    },
  });
  const router = useRouter();
  const { notify } = useApp();

  const onSubmit = async (data: any) => {
    setLoading(true);

    try {
      const response = await TPSAPI.registercompany(data);
      gtmRegisterUser();
      router.push('/');
    } catch (error) {
      notify('Please try again later', 'error', 'top-right');
    }
    setLoading(false);
  };
  const { dirtyFields, errors } = formState;
  const [comanyIdLabel, setCompanyIdLabel] = React.useState<string>(
    intl.formatMessage({
      defaultMessage: 'Company ID',
      id: 'label.CompanyID',
    })
  );
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  return (
    <AuthContainer isRegister={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack wrap={true} gravity="center" otherClass="tablet:w-full min-tablet:pr-16">
          <div className="md:max-w-screen-sm tablet:w-full">
            <div className="w-full px-10 py-10 overflow-auto bg-white dark:bg-dark-200 border-0 min-tablet:px-16 rounded-4xl min-tablet:max-h-100-36 tablet:rounded-b-none">
              <Header title="Tell us a bit about your company " />
              <Stack fitX={true} padding={true}>
                <Input
                  name="name"
                  innerRef={register}
                  validation={getValidation('name')}
                  type="text"
                  label={intl.formatMessage({
                    defaultMessage: 'Company Name',
                    id: 'label.CompanyDetailsName',
                  })}
                  fullWidth={true}
                />
              </Stack>
              <Stack otherClass="relative" fitX={true} padding={true} zIndex="z-30">
                <Controller
                  control={control}
                  name="address"
                  render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                    <AddressField
                      placeholderText={intl.formatMessage({
                        defaultMessage: 'Write an address here',
                        id: 'label.Writeanaddresshere',
                      })}
                      innerRef={register}
                      selName="address"
                      api={null}
                      label="Address"
                      validation={getValidation('address')}
                      source="here"
                      onChange={(data: ISugestion) => {
                        onChange(data);
                        if (data.country === 'Romania') {
                          setCompanyIdLabel(
                            intl.formatMessage({
                              defaultMessage: 'Company CUI',
                              id: 'label.RoCif',
                            })
                          );
                        }
                      }}
                      fullWidth={true}
                    />
                  )}
                />
                <div className="excl-mark pulse-disclaimer absolute right-3 cursor-pointer" data-tip data-for="address">
                  i
                </div>
              </Stack>
              <Stack otherClass="relative" fitX={true} spacing="xl" padding={true} zIndex="z-20">
                <Input
                  name="companyId"
                  innerRef={register}
                  validation={getValidation('companyId')}
                  type="text"
                  label={comanyIdLabel}
                  fullWidth={true}
                />
                <Input
                  name="additionalId"
                  innerRef={register}
                  validation={getValidation('additionalId')}
                  type="text"
                  label={intl.formatMessage({
                    defaultMessage: 'Additional Identification',
                    id: 'label.AdditionalInfo',
                  })}
                  fullWidth={true}
                />
                <div className="excl-mark pulse-disclaimer absolute right-3 cursor-pointer" data-tip data-for="company">
                  i
                </div>
              </Stack>
              <Stack otherClass="relative" fitX={true} padding={true}>
                <Controller
                  control={control}
                  name="vat"
                  render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                    <SelectInput
                      options={optionsValues}
                      onChange={e => {
                        onChange(e.value);
                      }}
                      placeholderText={intl.formatMessage({
                        defaultMessage: 'Vat',
                        id: 'label.Vat',
                      })}
                      validation={getValidation('vat')}
                      isMulti={false}
                      fullWidth={true}
                      isClearable={false}
                      selName="vat"
                      label="VAT"
                    />
                  )}
                />
                <div className="excl-mark pulse-disclaimer absolute right-3 cursor-pointer" data-tip data-for="vat">
                  i
                </div>
              </Stack>
              <div className="flex items-center justify-center w-full pt-5 shadow-top-border">
                <div className="text-center">
                  <Button type="submit" color="primary" loading={loading} fullWidth={true}>
                    {intl.formatMessage({
                      defaultMessage: 'Create Company details',
                      id: 'Button.CreateCompanydetails',
                    })}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Stack>
      </form>
      <div>
        {optionsTooltip.map(tooltip => {
          return (
            <ReactTooltip key={tooltip.value} id={tooltip.value} className="custom-tooltip" place="left">
              <div className="flex flex-col preview-media-tooltip">
                <div className="flex row-tooltip-container">
                  <span className="onboarding-text-tooltip">{tooltip.label}</span>
                </div>
              </div>
            </ReactTooltip>
          );
        })}
      </div>
    </AuthContainer>
  );
};
export default RegisterFormStep2;
