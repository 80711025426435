import React, { useState } from 'react';
import RadioBox from '../atoms/RadioBox';
import { Stack } from 'components/layout';
import { FilterCampaignList } from '../molecules';
import { useRouter } from 'next/router';

import { ICampaignList } from 'interface/ICampaign';
import CampaignsList from './Dashboard/CampaignsList';
import { oneLine } from 'common-tags';
import ClassicCard from '../atoms/ClassicCard';
import SearchBar from '../molecules/Search';
import Link from 'next/link';
import { Button } from 'components/common/atoms';


type IProps = {
  campaigns: ICampaignList[] | null | undefined;
  draft: ICampaignList[] | null | undefined;
  status: boolean;
  errorMessage: string;
};

const MyCampaigns: React.FC<IProps> = ({ campaigns, status, errorMessage, draft }: IProps) => {
  const [options, setOptions] =  useState<any> ([
    { value: 'grid', icon: 'Category', label: '', checked: true },
    { value: 'list', icon: 'Filter', label: '', checked: false },
  ]);
  const router = useRouter();

  const [userCampaigns, setUserCampaigns] = useState<ICampaignList[] | null>(campaigns || null);
  const [userDrafts, setUserDrafts] = useState<ICampaignList[] | null | undefined>(draft || null);
  const [searchText, setSearchText] = useState<string>('');
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [searchFilters, setSearchFilters] = useState<string[]>([]);
  const [displayType, setDisplayType] = useState<string>('grid');

  React.useEffect(() => {
    const updatedOptions = options.map((option: any) => ({
        ...option,
        checked: option.value === displayType ? true : false,
    }));
    setOptions(updatedOptions);
}, [displayType]);

  React.useEffect(() => {
    setUserDrafts(draft);
    onSearch();
  }, [draft]);

  React.useEffect(() => {
    if (searchText.length > 0 || searchFilters.length > 0)
      router.push(
        {
          pathname: '/mycampaigns',
          query: {
            q: searchText,
            filters: searchFilters.join(','),
          },
        },
        undefined,
        { shallow: true }
      );
    else {
      router.push(
        {
          pathname: '/mycampaigns',
        },
        undefined,
        { shallow: true }
      );
    }

    //router.replace(`/?q=${searchText}`)
    onSearch();
  }, [searchText, searchFilters.length]);

  const onSearch = (): void => {
    let campaignsFilter: any = campaigns;

    let campaignsFilterDraft: any = draft;
    if (searchText.length > 0) {
      campaignsFilter = campaignsFilter?.filter((el: ICampaignList) => {
        return el.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
      });

      campaignsFilterDraft = draft?.filter((el: ICampaignList) => {
        return el.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
      });
    }
    if (searchFilters.length > 0) {
      campaignsFilter = campaignsFilter?.filter((el: ICampaignList) => {
        return searchFilters.indexOf(el.status) > -1;
      });
    }
    setUserCampaigns(campaignsFilter);
    setUserDrafts(campaignsFilterDraft);
  };
  return (
    <>
      <ClassicCard otherClasses={`px-5 py-4 mb-7`}>
        <div className={oneLine`w-full flex items-center  relative z-50 justify-between`}>
          <div className="flex items-center justify-between flex-1 space-x-7 pr-7">
            <SearchBar
              isOpenSearch={() => {
                setIsSearchOpen(true);
              }}
              onSearch={e => setSearchText(e)}
            />
            <div className="md:flex-1">
              <FilterCampaignList onSearch={e => setSearchFilters(e)} />
            </div>
          </div>
          <div className="w-fit">
            <RadioBox
              onChange={(e: any) => {
                setDisplayType(e.target.value);
              }}
              justIcons={true}
              boxSize="small"
              key={`option-${options[0].checked}`}
              name="primary"
              options={options}
              label=""
            />
          </div>
        </div>
      </ClassicCard>
      <Stack fitX={true} spacing="xl" direction="horizontal" wrap={true}>
        <CampaignsList
          key={`${userCampaigns?.length}_${searchFilters.join('_')}`}
          index="2"
          displayType={displayType}
          title="Campaigns"
          campaigns={userCampaigns}
          status={status}
          errorMessage={errorMessage}
        />
      </Stack>
    </>
  );
};

MyCampaigns.defaultProps = {
  status: true,
  errorMessage: '',
};
export default MyCampaigns;
