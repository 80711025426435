import React, { useCallback, useState } from 'react';
import { Stack } from 'components/layout';
import { ChevronLeft, ChevronRight, Notification } from 'react-iconly';
import { useIntl, FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Button } from '../atoms';
import { useApp } from 'components/app';
import { JWT } from '@api/users';

type IProps = {
  title?: any;
  subtitle?: string;
  subtitleMob?: string;
  hideTitleOnMobile?: boolean;
  backBtn?: string;
  hideAddBtn?: boolean;
  showShareBtn?: boolean;
  copyClipboard?: boolean;
  hasLogo?: boolean;
  shareID?: string;
  hint?: boolean;
  children?: React.ReactNode;
};

const Navbar: React.FC<IProps> = ({
  children,
  title,
  subtitle,
  hideTitleOnMobile,
  subtitleMob,
  backBtn,
  hideAddBtn,
  showShareBtn = false,
  copyClipboard,
  shareID,
  hasLogo = false,
  hint,
}: IProps) => {
  const hasNotifications = true;
  const hasUnreadNotifications = true;
  const ref = React.createRef();
  const router = useRouter();
  const { notify } = useApp();

  const onClickRedirect = () => {
    router.push('/publish/basic');
  };

  const onClickShare = () => {
    router.push('/media-plan/' + title);
  };

  const onClickHandle = () => {
    router.push(backBtn || '');
  };
  const onCopyToClipboard = () => {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'https://portal.seeblindspot.com/media-plan/' + shareID;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    notify('The link has been copied to clipboard!', 'success');
  };

  return (
    <div
      className={`h-24 flex-shrink-0 tablet:border-b tablet:border-bordercolor dark:tablet:border-bordercolordark mb-4
        ${copyClipboard ? 'mt-4' : ''}`}
    >
      <div
        className={`flex  items-center h-full px-7  md:pr-7 ${hideTitleOnMobile ? '' : ''} ${
          !copyClipboard ? 'pr-24 min-tablet:justify-between' : 'justify-between'
        }`}
      >
        {backBtn ? (
          <Button color="white" justIcon={true} className="mr-3 md:hidden" onClick={onClickHandle}>
            <div className="text-lightGrey dark:text-dark-400">
              <ChevronLeft set="light" />
            </div>
          </Button>
        ) : (
          ''
        )}
        <div className={'flex flex-col md:flex-row md:items-center'}>
          {hasLogo && (
            <div className="w-40 md:mr-10">
              <img
                src="/images/logo-blindspot.svg"
                width="280"
                height="115"
                alt="Blindspot"
                className="block dark:hidden"
              />
              <img
                src="/images/logo-blindspot-darkmode.svg"
                width="280"
                height="115"
                alt="Blindspot"
                className="hidden dark:block"
              />
            </div>
          )}
          <div className={`flex flex-col md:flex-row h-full ${hideTitleOnMobile ? 'min-md:block hidden' : ''}`}>
            <div
              className="text-base text-lightGrey md:text-black md:text-opacity-60 md:text-xl blindspot-title dark:text-white"
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
            {(subtitle && (
              <div className="text-xl font-bold border-black text-dark-default md:font-semibold md:pl-3 md:ml-3 md:border-l dark:border-lightGrey border-opacity-60 blindspot-title dark:text-dark-400">
                {subtitle}
              </div>
            )) ||
              null}
            {(subtitleMob && (
              <div className="text-xl font-bold border-black text-dark-default md:font-semibold md:pl-3 md:ml-3 md:border-l dark:border-lightGrey border-opacity-60 md:hidden blindspot-title dark:text-dark-400">
                {subtitleMob}
              </div>
            )) ||
              null}
          </div>
        </div>

        <div className={backBtn ? 'hidden min-tablet:block' : children ? 'ml-auto' : ''}>
          {children ? (
            <div className="flex items-center justify-end ml-4">{children}</div>
          ) : (
            <div className="flex items-center justify-end">
              {/* {(hasNotifications && (
              <div className={`ico-notification mr-3 ${(hasUnreadNotifications && 'active') || ''}`}>
                <Link href="/">
                  <Notification set="light" primaryColor="#858D96" size="medium" />
                </Link>
              </div>
            )) ||
              ''} */}
              <div className={!copyClipboard ? 'hidden' : 'pr-4'}>
                <Button onClick={onCopyToClipboard} tag="div">
                  Get Share Link
                </Button>
              </div>

              {showShareBtn && (
                <div className={!showShareBtn ? 'hidden' : 'pr-4'}>
                  <a target="_blank" href={'/media-plan/' + shareID} rel="noopener noreferrer">
                    <Button tag="div">Share Campaign</Button>
                  </a>
                </div>
              )}

              {hint && (
                <div className="flex flex-row items-center navbar-hint">
                  <p className="mr-4 text-sm dark:text-dark-300 text-dark-200">browse over 1 Million billboards</p>
                  <img className="mt-6 mr-4 w-14" src="/images/arrow-referral.svg" />
                </div>
              )}
              <div className={`${hideAddBtn ? 'hidden' : hasLogo ? 'hidden min-tablet:block' : ''}`}>
                <Button onClick={onClickRedirect} tag="div" className='whitespace-nowrap'>
                  Create campaign
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Navbar;
