import { oneLine } from 'common-tags';
import { Portal } from 'components/common/atoms/Portal';
import { IListLocation, ILocationContentType } from 'interface/ILocation';
import React, { useEffect, useState, useContext, useRef } from 'react';

import { PublishContext } from 'context/publish/publish.provider';
import { IPublishType } from 'context/publish/publish.reducer';
import PulseLoader from 'react-spinners/PulseLoader';
import DataTable from 'react-data-table-component';
import { Button, Input, SelectInput } from 'components/common/atoms';
import SearchBar from 'components/common/molecules/Search';
import { FilterCampaignList } from 'components/common/molecules';

interface MassUpdatePphProps {
  onClose(): void;
}
const lineContainerClasses = oneLine`
  flex flex-col justify-start
  `;

const valueClasses = oneLine`
  text-sm
  text-dark-default
  dark:text-white
  font-semibold md:font-bold
  `;

const options = [
  {
    label: '10',
    value: '360',
  },
  {
    label: '20',
    value: '180',
  },
  {
    label: '30',
    value: '120',
  },
  {
    label: '40',
    value: '90',
  },
  {
    label: '50',
    value: '72',
  },
  {
    label: '60',
    value: '60',
  },
];
export const MassUpdatePph = ({ onClose }: MassUpdatePphProps) => {
  const { publishState, dispatch } = React.useContext(PublishContext);
  const [locations, setLocations] = useState<IListLocation[]>([]);
  const selectedLocations = useRef<string[]>([]);
  const [newPph, setNewPph] = useState<number>(0);

  useEffect(() => {
    setLocations(publishState?.locations || []);
  }, []);

  const searchItem = (e: string) => {
    const newLocations = publishState?.locations?.filter((location: IListLocation) => {
      return location.location.n.toLowerCase().includes(e.toLowerCase());
    });
    setLocations(newLocations || []);
  };

  const columns = React.useMemo(
    () => [
      {
        name: 'Name',
        selector: 'name',
        sortable: false,
        accessor: 'name',
        grow: 10,
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            <label htmlFor={props.location.uuid} className={` ${valueClasses}`}>
              {props.location.n}
            </label>
          </div>
        ),
      },
      {
        name: 'PPH',
        selector: 'pph',
        sortable: false,
        accessor: 'pph',
        grow: 3,
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            <div className={` ${valueClasses}`}>{(3600 / props.pph).toString()}</div>
          </div>
        ),
      },
    ],
    [locations]
  );
  const handleChange = (state: { allSelected: boolean; selectedCount: number; selectedRows: any[] }) => {
    selectedLocations.current = state.selectedRows.map((row: any) => row.location.uuid);
  };

  const save = () => {
    dispatch({
      type: IPublishType.updatePph,
      payload: {
        locationUuids: selectedLocations.current,
        pph: newPph,
      },
    });
    onClose();
  };

  return (
    <Portal otherClass={`max-w-[859px] min  w-full mx-auto !overflow-y-hidden`}>
      <div
        className={oneLine`flex flex-col rounded-3xl text-left h-full transform transition-all p-7 w-full overflow-y-hidden`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className={oneLine`pb-3 flex items-center justify-between`}>
          <div className="dark:text-dark-400">Mass update pph</div>
          <span
            className={oneLine`text-lg  font-bold text-body cursor-pointer ease-in-out transition duration-300 hover:rotate-180`}
            aria-hidden="true"
            onClick={() => {
              onClose();
            }}
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.98959 9.01041L21.0104 21.0312M21.0104 9.01041L8.98959 21.0312"
                stroke="#A7ACB3"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
            </svg>
          </span>
        </div>
        <div>
          <div className="flex items-center justify-between flex-1 space-x-7 pr-7">
            <div className="w-6/12">
              <SearchBar isOpenSearch={() => {}} onSearch={e => searchItem(e)} />
            </div>
            <div className="w-6/12">
              <SelectInput
                options={options}
                onChange={e => {
                  setNewPph(parseInt(e.value, 10));
                }}
                label="Update PPH"
                isMulti={false}
                fullWidth={true}
                isClearable={false}
                selName="pph"
              />
            </div>
          </div>
        </div>
        <div
          className={oneLine`w-full flex-col flex-1  flex justify-center items-center max-w-[980px]
                      h-[200px] `}
        >
          <div className="w-full py-5 overflow-scroll">
            <DataTable
              noHeader={true}
              pagination={false}
              columns={columns}
              data={locations}
              selectableRows
              onSelectedRowsChange={handleChange}
              className="table-simulate "
            />
          </div>
        </div>
        <div className="flex items-center justify-center w-full pt-4 min-tablet:space-x-4 space-x-0 min-tablet:space-y-0 space-y-4 min-tablet:flex-row flex-col">
          <div className="max-w-[300px] w-full">
            <Button type="button" color="secondary" onClick={onClose} fullWidth={true}>
              Close
            </Button>
          </div>
          <div className="max-w-[300px] w-full">
            <Button
              type="button"
              color="primary"
              onClick={save}
              fullWidth={true}
              disabled={selectedLocations.current.length === 0 || newPph === 0}
            >
              Update pph
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
};
